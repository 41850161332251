import React, { Component } from 'react';
import styles from './Search.module.css';
import { Input } from 'reactstrap';
import Button from './Button';

class Search extends Component {
  state = {};
  inputChange = (event) => {
    this.setState({
      input: event.target.value,
      inputError: '',
    });
  };

  inputBlur = (event) => {
    let { input } = this.state;
    if (!input) return this.setState({ inputError: 'KVK nummer niet ingevuld' });
    if (!input.match(/^[0-9]{8}$/)) return this.setState({ inputError: 'KVK nummer klopt niet' });
    this.props.history.push(`/${input}`);
  };
  
  render() {
    let { inputError } = this.state;
    return (
      <div>
        <div className={styles.input}>
          <Input type="text" onChange={this.inputChange} onBlur={this.inputBlur} />
          <Button>Zoek bedrijf</Button>
        </div>
        <div style={{ fontSize: '0.9em', color: 'red' }}>{inputError}</div>
      </div>
    );
  }
}
export default Search;
