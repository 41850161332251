import React from 'react';
// import logo from '../images/logo.svg';
import dop from '../images/dop.png';
import styles from './Header.module.css';
import logoKVK from '../images/kvk-logo.png';
import logoRVO from '../images/rvo-logo.png';
import logoCBS from '../images/cbs-logo.svg';

const Header = (props) => {
  let version = 'v0';
  if (window.location.pathname.includes('v1')) {
    version = 'v1';
  }
  if (window.location.pathname.includes('v2')) {
    version = 'v2';
  }

  return (
    <div>
      <div className={styles.main}>
        <img
          style={{ height: '62px' }}
          className={styles.logo}
          src={logoRVO}
          alt="logo"
        />
        {(version === 'v1' || version === 'v2') && (
          <img
            style={{ height: '40px' }}
            className={styles.logo}
            src={logoKVK}
            alt="logo"
          />
        )}
        {version === 'v2' && (
          <img
            style={{ height: '48px' }}
            className={styles.logo}
            src={logoCBS}
            alt="logo"
          />
        )}
      </div>
      {/* <img style={{width:'100%'}} className={styles.dop} src={dop} alt="dop" /> */}
      {/* <div className={styles.topbar}></div>
      <div className={styles.main}>
        <div className={styles.left}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.title}>Wetten en Regels</div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
