import React from 'react';
import styles from './Vertrouwen.module.css';

export default function Vertrouwen() {
  return (
    <div className={styles.main}>
    <div className="dashblock colLeft" data-placement="1">
      <section className="tile width2 height2 _485">
        <div
          className="inner-tile has-actions basic-reference"
          id="tile_485"
          title="Ondernemersvertrouwen 4e kwartaal 2022
Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of nutsbedrijven)."
          data-externalcode="485"
          data-showdetailview="true"
          style={{outline: 'none'}}>
          <header>
            <h2 className="header tile-header no-subtitle">Ondernemersvertrouwen 4e kwartaal 2022</h2>
          </header>
          <div className="tile-content">
            <div className="tile-row">
              <div className="wHalf h4Row u-t-left v-align middle">
                <data className="datavalue" value="-7.1">
                  -7
                  <small>%</small> <small></small>
                </data>
                <p className="subtitle area" title="Groningen (prov.)">
                  Groningen (prov.)
                </p>
              </div>
              <div className="wHalf h4Row u-t-left v-align middle">
                <data className="datavalue compare" value="-0.9">
                  -0<small>,9</small>
                  <small>%</small> <small></small>
                </data>
                <p className="subtitle area compare" title="Nederland">
                  Nederland
                </p>
              </div>
            </div>
          </div>
          <footer>
            <div className="source" style={{maxWidth: '473px'}}>
              Bron: CBS
            </div>
            <data className="period" value=" ">
              
            </data>
          </footer>
          <a className="tile-link " href="'javascript:void(0);'" target="_blank">
            <i className="far fa-external-link fa-sm"></i>
            <span className="sr-only">
              Ondernemersvertrouwen 4e kwartaal 2022 Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft
              waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of
              nutsbedrijven).
            </span>
          </a>
        </div>
       
      </section>
      <section className="tile width2 height2 _486">
        <div
          className="inner-tile has-actions basic-reference"
          id="tile_486"
          title="Ondernemersvertrouwen 4e kwartaal 2021
Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of nutsbedrijven)."
          data-externalcode="486"
          data-showdetailview="true">
          <header>
            <h2 className="header tile-header no-subtitle">Ondernemersvertrouwen 4e kwartaal 2021</h2>
          </header>
          <div className="tile-content">
            <div className="tile-row">
              <div className="wHalf h4Row u-t-left v-align middle">
                <data className="datavalue" value="14.8">
                  14<small>,8</small>
                  <small>%</small> <small></small>
                </data>
                <p className="subtitle area" title="Groningen (prov.)">
                  Groningen (prov.)
                </p>
              </div>
              <div className="wHalf h4Row u-t-left v-align middle">
                <data className="datavalue compare" value="19.9">
                  19<small>,9</small>
                  <small>%</small> <small></small>
                </data>
                <p className="subtitle area compare" title="Nederland">
                  Nederland
                </p>
              </div>
            </div>
          </div>
          <footer>
            <div className="source" style={{maxWidth: '473px'}}>
              Bron: CBS
            </div>
            <data className="period" value=" ">
              
            </data>
          </footer>
          <a className="tile-link " href="'javascript:void(0);'" target="_blank">
            <i className="far fa-external-link fa-sm"></i>
            <span className="sr-only">
              Ondernemersvertrouwen 4e kwartaal 2021 Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft
              waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of
              nutsbedrijven).
            </span>
          </a>
        </div>
       
      </section>
      <section className="tile chart width2 height4 _488">
        <div
          className="inner-tile has-actions"
          id="tile_488"
          title="Ontwikkeling ondernemersvertrouwen
Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of nutsbedrijven)."
          data-externalcode="488"
          data-showdetailview="true">
          <header>
            <h2 className="tile-header no-subtitle">Ontwikkeling ondernemersvertrouwen</h2>
          </header>
          <div className="table-wrapper" tabIndex="-1">
            <table className="tile__rawdata">
              
              <caption>Ontwikkeling ondernemersvertrouwen</caption>
              <thead>
                <tr>
                  <th id="hs-488" title="">
                    Periode
                  </th>
                  <th id="hr-488" title="Groningen (prov.)">
                    Groningen (prov.)
                  </th>
                  <th id="hrr-488" title="Nederland">
                    Nederland
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th id="hv-488-0" headers="hs-488">
                    3e kwartaal 2020
                  </th>
                  <td headers="hv-488-0 hs-488" className="">
                    -16,1
                  </td>
                  <td headers="hv-488-0 hr-488" className="">
                    -19,3
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-1" headers="hs-488">
                    4e kwartaal 2020
                  </th>
                  <td headers="hv-488-1 hs-488" className="">
                    -12,9
                  </td>
                  <td headers="hv-488-1 hr-488" className="">
                    -4,0
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-2" headers="hs-488">
                    1e kwartaal 2021
                  </th>
                  <td headers="hv-488-2 hs-488" className="">
                    -12,1
                  </td>
                  <td headers="hv-488-2 hr-488" className="">
                    -6,0
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-3" headers="hs-488">
                    2e kwartaal 2021
                  </th>
                  <td headers="hv-488-3 hs-488" className="">
                    9,0
                  </td>
                  <td headers="hv-488-3 hr-488" className="">
                    2,3
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-4" headers="hs-488">
                    3e kwartaal 2021
                  </th>
                  <td headers="hv-488-4 hs-488" className="">
                    19,0
                  </td>
                  <td headers="hv-488-4 hr-488" className="">
                    18,4
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-5" headers="hs-488">
                    4e kwartaal 2021
                  </th>
                  <td headers="hv-488-5 hs-488" className="">
                    14,8
                  </td>
                  <td headers="hv-488-5 hr-488" className="">
                    19,9
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-6" headers="hs-488">
                    1e kwartaal 2022
                  </th>
                  <td headers="hv-488-6 hs-488" className="">
                    5,3
                  </td>
                  <td headers="hv-488-6 hr-488" className="">
                    6,4
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-7" headers="hs-488">
                    2e kwartaal 2022
                  </th>
                  <td headers="hv-488-7 hs-488" className="">
                    7,3
                  </td>
                  <td headers="hv-488-7 hr-488" className="">
                    16,8
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-8" headers="hs-488">
                    3e kwartaal 2022
                  </th>
                  <td headers="hv-488-8 hs-488" className="">
                    1,6
                  </td>
                  <td headers="hv-488-8 hr-488" className="">
                    8,2
                  </td>
                </tr>
                <tr>
                  <th id="hv-488-9" headers="hs-488">
                    4e kwartaal 2022
                  </th>
                  <td headers="hv-488-9 hs-488" className="">
                    -7,1
                  </td>
                  <td headers="hv-488-9 hr-488" className="">
                    -0,9
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="content" id="_HCTile_488" title="" aria-hidden="true">
            <svg viewBox="0 0 480 260">
              <g></g>
              <g className="legend" aria-hidden="true" transform="translate(151.8671875,-31)">
                <rect style={{display: 'block'}} fill="transparent" x="0" width="178.265625" y="253" height="15"></rect>
                <g className="legendrow">
                  <text
                    fontSize="12px"
                    fill="rgb(52, 52, 52)"
                    data-legendobject="%7B%22type%22%3A%22point%22%2C%22color%22%3A%22rgb(55%2C%20127%2C%20149)%22%2C%22text%22%3A%22Groningen%20(prov.)%22%7D"
                    x="14"
                    y="265">
                    Groningen (prov.)
                  </text>
                  <circle
                    cx="6"
                    cy="261"
                    r="6"
                    fill="rgb(55, 127, 149)"
                    data-legendobject="%7B%22type%22%3A%22point%22%2C%22color%22%3A%22rgb(55%2C%20127%2C%20149)%22%2C%22text%22%3A%22Groningen%20(prov.)%22%7D"></circle>
                  <text
                    fontSize="12px"
                    fill="rgb(52, 52, 52)"
                    data-legendobject="%7B%22type%22%3A%22point%22%2C%22color%22%3A%22rgb(117%2C%20117%2C%20117)%22%2C%22text%22%3A%22Nederland%22%7D"
                    x="125.4375"
                    y="265">
                    Nederland
                  </text>
                  <circle
                    cx="117.4375"
                    cy="261"
                    r="6"
                    fill="rgb(117, 117, 117)"
                    data-legendobject="%7B%22type%22%3A%22point%22%2C%22color%22%3A%22rgb(117%2C%20117%2C%20117)%22%2C%22text%22%3A%22Nederland%22%7D"></circle>
                </g>
              </g>
              <g className="chart" transform="translate(0,0)">
                <g className="axes" transform="translate(0,25)">
                  <rect width="479" height="131" fill="rgb(255, 255, 255)" x="1" y="0"></rect>
                  <g
                    className="x axis"
                    aria-hidden="true"
                    fill="none"
                    fontSize="10"
                    fontFamily="sans-serif"
                    textAnchor="middle"
                    transform="translate(0,131)">
                    <path className="domain" stroke="rgb(52, 52, 52)" d="M0,0V0.5H480V0" strokeWidth="1px"></path>
                    <g
                      className="tick"
                      opacity="1"
                      transform="translate(33.2421875,0)"
                      data-title="3e
kwartaal
2020"
                      data-code="0">
                      <line stroke="#000" y2="0" visibility="hidden"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        y="10"
                        dy="0.71em"
                        textAnchor="middle"
                        transform="rotate(0, 0,10)"
                        data-title="3e
kwartaal
2020"
                        data-code="0"
                        fontSize="12px">
                        <tspan x="0" y="10" dy="0.71em">
                          3e
                        </tspan>
                        <tspan x="0" y="10" dy="1.81em">
                          kwartaal
                        </tspan>
                        <tspan x="0" y="10" dy="2.91em">
                          2020
                        </tspan>
                      </text>
                    </g>
                    <g
                      className="tick"
                      opacity="1"
                      transform="translate(175.66145833333334,0)"
                      data-title="2e
kwartaal
2021"
                      data-code="3">
                      <line stroke="#000" y2="0" visibility="hidden"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        y="10"
                        dy="0.71em"
                        textAnchor="middle"
                        transform="rotate(0, 0,10)"
                        data-title="2e
kwartaal
2021"
                        data-code="3"
                        fontSize="12px">
                        <tspan x="0" y="10" dy="0.71em">
                          2e
                        </tspan>
                        <tspan x="0" y="10" dy="1.81em">
                          kwartaal
                        </tspan>
                        <tspan x="0" y="10" dy="2.91em">
                          2021
                        </tspan>
                      </text>
                    </g>
                    <g
                      className="tick"
                      opacity="1"
                      transform="translate(270.6076388888889,0)"
                      data-title="4e
kwartaal
2021"
                      data-code="5">
                      <line stroke="#000" y2="0" visibility="hidden"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        y="10"
                        dy="0.71em"
                        textAnchor="middle"
                        transform="rotate(0, 0,10)"
                        data-title="4e
kwartaal
2021"
                        data-code="5"
                        fontSize="12px">
                        <tspan x="0" y="10" dy="0.71em">
                          4e
                        </tspan>
                        <tspan x="0" y="10" dy="1.81em">
                          kwartaal
                        </tspan>
                        <tspan x="0" y="10" dy="2.91em">
                          2021
                        </tspan>
                      </text>
                    </g>
                    <g
                      className="tick"
                      opacity="1"
                      transform="translate(365.55381944444446,0)"
                      data-title="2e
kwartaal
2022"
                      data-code="7">
                      <line stroke="#000" y2="0" visibility="hidden"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        y="10"
                        dy="0.71em"
                        textAnchor="middle"
                        transform="rotate(0, 0,10)"
                        data-title="2e
kwartaal
2022"
                        data-code="7"
                        fontSize="12px">
                        <tspan x="0" y="10" dy="0.71em">
                          2e
                        </tspan>
                        <tspan x="0" y="10" dy="1.81em">
                          kwartaal
                        </tspan>
                        <tspan x="0" y="10" dy="2.91em">
                          2022
                        </tspan>
                      </text>
                    </g>
                    <g
                      className="tick"
                      opacity="1"
                      transform="translate(458.1953125,0)"
                      data-title="4e
kwartaal
2022"
                      data-code="9">
                      <line stroke="#000" y2="0" visibility="hidden"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        y="10"
                        dy="0.71em"
                        textAnchor="middle"
                        transform="rotate(0, 0,10)"
                        data-title="4e
kwartaal
2022"
                        data-code="9"
                        fontSize="12px">
                        <tspan x="0" y="10" dy="0.71em">
                          4e
                        </tspan>
                        <tspan x="0" y="10" dy="1.81em">
                          kwartaal
                        </tspan>
                        <tspan x="0" y="10" dy="2.91em">
                          2022
                        </tspan>
                      </text>
                    </g>
                  </g>
                  <g
                    className="y axis left"
                    aria-hidden="true"
                    fill="none"
                    fontSize="10"
                    fontFamily="sans-serif"
                    textAnchor="end">
                    <path className="domain" stroke="rgb(52, 52, 52)" d="M0,131.5H0.5V0.5H0" opacity="0"></path>
                    <g className="tick" opacity="1" transform="translate(0,131.5)">
                      <line stroke="rgb(52, 52, 52)" x2="480" opacity="0.2"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        x="2"
                        dy="-4"
                        transform="rotate(0,0,0)"
                        textAnchor="start"
                        fontSize="12px">
                        -24%
                      </text>
                    </g>
                    <g className="tick" opacity="1" transform="translate(0,98.75)">
                      <line stroke="rgb(52, 52, 52)" x2="480" opacity="0.2"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        x="2"
                        dy="-4"
                        transform="rotate(0,0,0)"
                        textAnchor="start"
                        fontSize="12px">
                        -8%
                      </text>
                    </g>
                    <g className="tick" opacity="1" transform="translate(0,66)">
                      <line stroke="rgb(52, 52, 52)" x2="480" opacity="0.2"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        x="2"
                        dy="-4"
                        transform="rotate(0,0,0)"
                        textAnchor="start"
                        fontSize="12px">
                        8%
                      </text>
                    </g>
                    <g className="tick" opacity="1" transform="translate(0,33.25)">
                      <line stroke="rgb(52, 52, 52)" x2="480" opacity="0.2"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        x="2"
                        dy="-4"
                        transform="rotate(0,0,0)"
                        textAnchor="start"
                        fontSize="12px">
                        24%
                      </text>
                    </g>
                    <g className="tick" opacity="1" transform="translate(0,0.5)">
                      <line stroke="rgb(52, 52, 52)" x2="480" opacity="0.2"></line>
                      <text
                        fill="rgb(52, 52, 52)"
                        x="2"
                        dy="-4"
                        transform="rotate(0,0,0)"
                        textAnchor="start"
                        fontSize="12px">
                        40%
                      </text>
                    </g>
                    <text
                      y="65.5"
                      x="-11.734375"
                      fontSize="12px"
                      fill="rgb(52, 52, 52)"
                      transform="rotate(-90, -11.734375,65.5)"
                      style={{textAnchor: 'middle'}}>
                      <tspan></tspan>
                    </text>
                    <g className="tick" transform="translate(0,81.875)">
                      <line className="zero" opacity="1" stroke="rgb(52, 52, 52)" x2="480"></line>
                    </g>
                  </g>
                </g>
                <g className="elements" transform="translate(0,25)" clipPath="url(#chart-clip__HCTile_488_0)">
                  <g className="lines">
                    <g className="line" data-code="Nederland">
                      <path
                        className="line line_1"
                        d="M32.7421875,121.3796875L80.21527777777777,90.0625L127.68836805555556,94.15625L175.16145833333334,77.1671875L222.63454861111111,44.212500000000006L270.1076388888889,41.142187500000006L317.5807291666667,68.775L365.05381944444446,47.48750000000001L412.52690972222223,65.09062499999999L460,83.7171875"
                        stroke="rgb(117, 117, 117)"
                        fill="none"
                        strokeWidth="2"></path>
                      <g className="points">
                        <circle
                          className="point"
                          cx="32.7421875"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="121.3796875"
                          data-value="-19,3%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2020, Nederland: -19,3%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="80.21527777777777"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="90.0625"
                          data-value="-4,0%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2020, Nederland: -4,0%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="127.68836805555556"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="94.15625"
                          data-value="-6,0%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="1e
kwartaal
2021, Nederland: -6,0%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="175.16145833333334"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="77.1671875"
                          data-value="2,3%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="2e
kwartaal
2021, Nederland: 2,3%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="222.63454861111111"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="44.212500000000006"
                          data-value="18,4%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2021, Nederland: 18,4%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="270.1076388888889"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="41.142187500000006"
                          data-value="19,9%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2021, Nederland: 19,9%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="317.5807291666667"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="68.775"
                          data-value="6,4%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="1e
kwartaal
2022, Nederland: 6,4%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="365.05381944444446"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="47.48750000000001"
                          data-value="16,8%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="2e
kwartaal
2022, Nederland: 16,8%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="412.52690972222223"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="65.09062499999999"
                          data-value="8,2%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2022, Nederland: 8,2%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="460"
                          data-title="Nederland"
                          data-code="Nederland"
                          cy="83.7171875"
                          data-value="-0,9%"
                          r="4"
                          fill="rgb(117, 117, 117)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2022, Nederland: -0,9%"
                          tabIndex="-1"></circle>
                      </g>
                    </g>
                    <g className="line" data-code="1">
                      <path
                        className="line line_2"
                        d="M32.7421875,114.8296875L80.21527777777777,108.2796875L127.68836805555556,106.6421875L175.16145833333334,63.453125L222.63454861111111,42.984375L270.1076388888889,51.58125000000001L317.5807291666667,71.0265625L365.05381944444446,66.9328125L412.52690972222223,78.6L460,96.4078125"
                        stroke="rgb(55, 127, 149)"
                        fill="none"
                        strokeWidth="2"></path>
                      <g className="points">
                        <circle
                          className="point"
                          cx="32.7421875"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="114.8296875"
                          data-value="-16,1%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2020, Groningen (prov.): -16,1%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="80.21527777777777"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="108.2796875"
                          data-value="-12,9%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2020, Groningen (prov.): -12,9%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="127.68836805555556"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="106.6421875"
                          data-value="-12,1%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="1e
kwartaal
2021, Groningen (prov.): -12,1%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="175.16145833333334"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="63.453125"
                          data-value="9,0%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="2e
kwartaal
2021, Groningen (prov.): 9,0%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="222.63454861111111"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="42.984375"
                          data-value="19,0%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2021, Groningen (prov.): 19,0%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="270.1076388888889"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="51.58125000000001"
                          data-value="14,8%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2021, Groningen (prov.): 14,8%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="317.5807291666667"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="71.0265625"
                          data-value="5,3%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="1e
kwartaal
2022, Groningen (prov.): 5,3%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="365.05381944444446"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="66.9328125"
                          data-value="7,3%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="2e
kwartaal
2022, Groningen (prov.): 7,3%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="412.52690972222223"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="78.6"
                          data-value="1,6%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="3e
kwartaal
2022, Groningen (prov.): 1,6%"
                          tabIndex="-1"></circle>
                        <circle
                          className="point"
                          cx="460"
                          data-title="Groningen (prov.)"
                          data-code="1"
                          cy="96.4078125"
                          data-value="-7,1%"
                          r="4"
                          fill="rgb(55, 127, 149)"
                          strokeWidth="2"
                          stroke="default"
                          aria-label="4e
kwartaal
2022, Groningen (prov.): -7,1%"
                          tabIndex="-1"></circle>
                      </g>
                    </g>
                  </g>
                </g>
                <clipPath id="chart-clip__HCTile_488_0">
                  <rect x="-4" y="-4" height="139" width="488"></rect>
                </clipPath>
              </g>
            </svg>
          </div>
          <footer>
            <div className="source" style={{maxWidth: '341px'}}>
              Bron: CBS
            </div>
            <data className="period" value=" | 3e kw 2020 - 4e kw 2022">
              
              | 3e kw 2020 - 4e kw 2022
            </data>
          </footer>
          <a className="tile-link " href="'javascript:void(0);'" target="_blank">
            <i className="far fa-external-link fa-sm"></i>
            <span className="sr-only">
              Ontwikkeling ondernemersvertrouwen Stemmingsindicator voor het bedrijfsleven, die de richting aangeeft
              waarin het BBP zich naar verwachting zal ontwikkelen. Het gaat om het totaal (exclusief financieel of
              nutsbedrijven).
            </span>
          </a>
        </div>
       
      </section>
    </div>
    </div>
  );
}
