import React, { Component } from 'react';
import styles from './Dashboard.module.css';
import axios from 'axios';
import Category from './Category';
import SearchAutocomplete from './SearchAutocomplete';
import Search from './Search';
import Header from './Header';
import Vertrouwen from './Vertrouwen';
import { withRouter } from 'react-router-dom';
import logoKVK from '../images/kvk-logo.png';
import logoRVO from '../images/rvo-logo.png';
import logoCBS from '../images/cbs-logo.svg';

function restructureData(regels) {
  regels = regels.map((regel) => {
    regel.terms = regel.taxonomy.reduce((org, tax) => {
      return tax.terms.reduce((org, term) => {
        return term.term.reduce((org, t) => [...org, t], org);
      }, org);
    }, []);
    return regel;
  });
  return regels;
}

class Dashboard extends Component {
  state = { searchType: 'naam' };

  getData(kvkNummer) {
    axios
      .get(`/api/inschrijvingen/${kvkNummer}`)
      .then(({ data }) => {
        data.regels = restructureData(data.regels);
        // console.log(data.regels);
        data.categories = data.categories.reduce((org, cat) => {
          org[cat] = [];
          return org;
        }, {});
        data.regels.forEach((regel) => {
          regel.terms.forEach((term) => {
            if (data.categories[term]) {
              data.categories[term].push(regel);
            }
          });
        });

        this.setState({ data, error: null });
        // console.log(data)
      })
      .catch((error) => {
        this.setState({ data: null, error: 'niks gevonden' });
        console.log(error.message);
      });
  }

  componentDidMount() {
    // let match = document.location.pathname.match(/^\/([0-9]{8})$/);
    let kvkNummer = this.props.location.search.split('=')[1];
    if (!kvkNummer || !kvkNummer.match(/^[0-9]{8}$/)) return;
    // let kvkNummer = match[1];
    this.getData(kvkNummer);
  }

  componentDidUpdate(prevProps) {
    let kvkNummer = this.props.location.search.split('=')[1];
    if (!kvkNummer || !kvkNummer.match(/^[0-9]{8}$/)) return;
    if (prevProps.location.search.split('=')[1] !== kvkNummer) {
      this.getData(kvkNummer);
    }
  }

  setSearchType = (event) => {
    this.setState({ searchType: event.target.value });
  };

  renderData(data, error, version) {
    if (error) return <div className={styles.error}>{error}</div>;
    if (!data) return null;
    let { inschrijving, categories } = data;

    let activiteiten;
    if (inschrijving.sbiActiviteiten) {
      // let activiteiten = inschrijving.sbiActiviteiten.filter((ba) => ba.isMainSbi);
      activiteiten = inschrijving.sbiActiviteiten
        .map((ba) => ba.sbiOmschrijving)
        .join(', ');
    }
    let codes;
    if (inschrijving.sbiActiviteiten) {
      // let activiteiten = inschrijving.sbiActiviteiten.filter((ba) => ba.isMainSbi);
      codes = inschrijving.sbiActiviteiten.map((ba) => ba.sbiCode).join(', ');
    }

    return (
      <div>
        <div className={styles.bedrijf}>
          <div style={{ fontSize: '1.2em', fontWeight: '500' }}>
            {inschrijving.naam}
          </div>
          <div>{'KVK nummer: ' + inschrijving.kvkNummer}</div>
          <div>{`Activiteiten: ${activiteiten}`}</div>
          <div>
            <span>{`SBI codes: ${codes}`}</span>
            {/* <div className={styles.button} style={{ float: 'right'  }} href="https://www.kvk.nl/inschrijven-en-wijzigen/wijzigen/">
            Mijn activiteiten kloppen niet, SBI code aanpassen bij KVK
            </div> */}
            <a
              style={{ float: 'right' }}
              href="https://www.kvk.nl/inschrijven-en-wijzigen/wijzigen/"
            >
              Mijn activiteiten kloppen niet, SBI code aanpassen bij KVK
            </a>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.regels}>
            <div className={styles.colHeader}>
              <div>
                <h3 className={styles.regelHeader}>Wetten en Regels</h3>
              </div>
              <img
                style={{ height: '62px' }}
                className={styles.logo}
                src={logoRVO}
                alt="logo"
              />
            </div>
            <div className={styles.disclaimer}>
              Let op! Dit is een selectie van wetgeving die in jouw branche
              interessant is. Alle wetten gelden voor iedereen. Je moet dus zelf
              opletten dat je aan alle wetgeving voldoet
            </div>
            <div className={styles.categoryContainer}>
              {Object.keys(categories).length === 0 && (
                <div
                  style={{
                    opacity: '0.4',
                    fontStyle: 'italic',
                    fontSize: '0.95em',
                    padding: '12px',
                  }}
                >
                  Geen wetten en regels gevonden voor dit bedrijf
                </div>
              )}
              {Object.keys(categories).map((categoryKey) => {
                let regels = categories[categoryKey];
                return (
                  <Category
                    key={categoryKey}
                    title={categoryKey}
                    regels={regels}
                    unfold={Object.keys(categories).length === 1}
                  />
                );
              })}
            </div>
          </div>
          {(version === 'v1' || version === 'v2') && (
            <div>
              <div className={styles.colHeader}>
                <div>
                  <h3>Stikstofmaatregelen in jouw sector</h3>
                </div>
                <img
                  style={{ height: '40px' }}
                  className={styles.logo}
                  src={logoKVK}
                  alt="logo"
                />
              </div>
              <div style={{ padding: '26px 0' }}>
                <div>
                  Nederland moet de uitstoot van stikstof de komende jaren flink
                  verminderen. De overheid komt daarom met maatregelen voor een
                  aantal sectoren. Voor de landbouw worden die maatregelen en de
                  gevolgen hiervan steeds duidelijker. De regels voor de
                  industrie maakt het kabinet in 2023 bekend. Voor de bouw en
                  transport is op dit moment nog weinig bekend. In dit artikel
                  vind je de belangrijkste informatie over stikstof in jouw
                  sector.
                </div>
                <div className={styles.source}>Bron: KVK</div>
              </div>
            </div>
          )}
          {version === 'v2' && (
            <div>
              <div className={styles.colHeader}>
                <div>
                  <h3>Ondernemersvertrouwen in jouw regio</h3>
                </div>
                <img
                  style={{ height: '48px' }}
                  className={styles.logo}
                  src={logoCBS}
                  alt="logo"
                />
              </div>
              <Vertrouwen></Vertrouwen>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    let { data, error, searchType } = this.state;
    let version = 'v0';
    if (window.location.pathname.includes('v1')) {
      version = 'v1';
    }
    if (window.location.pathname.includes('v2')) {
      version = 'v2';
    }

    return (
      <div className={styles[version]}>
        <div style={{ float: 'right', fontSize: '0.8em' }}>
          <a className={styles.versionLink} href={'/' + this.props.location.search}>{`v0`}</a>
          <a className={styles.versionLink} href={'/v1' + this.props.location.search}>{`v1`}</a>
          <a className={styles.versionLink} href={'/v2' + this.props.location.search}>{`v2`}</a>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.searchTypeContainer}>
            <div>Zoek op: </div>

            <input
              type="radio"
              value="naam"
              id="naam"
              name="searchType"
              checked={searchType === 'naam'}
              onChange={this.setSearchType}
            />
            <label htmlFor="naam">Handelsnaam</label>

            <input
              type="radio"
              value="nummer"
              id="nummer"
              name="searchType"
              checked={searchType === 'nummer'}
              onChange={this.setSearchType}
            />
            <label htmlFor="nummer">KVK nummer</label>
          </div>
          {searchType === 'naam' && (
            <SearchAutocomplete history={this.props.history} />
          )}
          {searchType === 'nummer' && <Search history={this.props.history} />}
        </div>
        <div style={{ padding: '0 12px' }}>
          <div className={styles.logos}>
            <img
              style={{ height: '62px' }}
              className={styles.logo}
              src={logoRVO}
              alt="logo"
            />
            {(version === 'v1' || version === 'v2') && (
              <img
                style={{ height: '40px' }}
                className={styles.logo}
                src={logoKVK}
                alt="logo"
              />
            )}
            {version === 'v2' && (
              <img
                style={{ height: '48px' }}
                className={styles.logo}
                src={logoCBS}
                alt="logo"
              />
            )}
          </div>
        </div>
        {this.renderData(data, error, version)}
      </div>
    );
  }
}

export default withRouter(Dashboard);
