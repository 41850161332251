import React, { Component } from 'react';
import styles from './App.module.css';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <div className={styles.App}>
          <div className={styles.AppContainer}>
            <Switch>
              <Route path="/" component={Dashboard} />
            </Switch>
          </div>
          <div className={styles.bottomBar}>
            <Header />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
