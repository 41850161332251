import React, { Component } from 'react';
import styles from './Category.module.css';
import Regel from './Regel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

class Category extends Component {
  state = {};

  componentDidMount() {
    if (this.props.unfold) this.setState({ unfolded: true });
  }

  render() {
    let { title, regels } = this.props;

    return (
      <div>
        <div className={styles.line} onClick={() => this.setState({ unfolded: !this.state.unfolded })}>
          <div className={styles.title}>{title}</div>
          <FontAwesomeIcon style={{ color: '#aa418c' }} icon={this.state.unfolded ? faChevronUp : faChevronDown} />
        </div>
        {this.state.unfolded && (
          <div className={styles.regelContainer}>
            {regels.map((regel, index) => (
              <Regel key={index} regel={regel} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Category;
