import React, { Component } from 'react';
import styles from './Regel.module.css';

class Regel extends Component {
  render() {
    let { regel } = this.props;
    // console.log(data.taxonomy[0].terms[0].term);
    // let link = regel.links[0].link.filter((l) => l.$.type === 'intern')[0];
    let link = regel['page-url']

    return (
      <div className={styles.main}>
        <div className={styles.header}>{regel.title}</div>
        <div className={styles.content}>
          <div>{regel.description}</div>
          {link && (
            <div>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {regel.title}
              </a>
            </div>
          )}
          {/* <ul>
            {regel.links.map((l, index1) =>
              l.link.map((link, index2) => {
                let hrefBase = '';
                if (link.$.type === 'intern') hrefBase = 'https://ondernemersplein.kvk.nl';

                return (
                  <li key={index1 + index2}>
                    <a href={hrefBase + link.url} target="_blank" rel="noopener noreferrer">
                      {link.title.join(', ')} 
                    </a>
                    <span style={{fontSize:'0.9em'}}>-{link.$.type}</span>
                  </li>
                );
              })
            )}
          </ul> */}

          {/* <ul>
            {regel.terms.map((t, i) => (
              <li key={i} style={{fontSize:'0.9em', fontStyle:'italic'}}>{t}</li>
            ))}
          </ul> */}

          {/* {data.content.map((cnt, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: cnt }} />
            
            ))} */}
        </div>
      </div>
    );
  }
}

export default Regel;
